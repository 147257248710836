<template>
  <div class="myPopupContent">
    <editor-topbar :title="headline" @closeClicked="closeEditor"/>
    <div class="whitePopupContent">
      <div class="err_msg commonError" v-show="missingFieldErrorMessage.length > 0">
        <icon name="fa-exclamation-circle"/>
        <span>{{ missingFieldErrorMessage }}</span>
      </div>
      <div class="commonFormHorScroll" :style="maxHeightStyle">
        <div class="commonColListWrap">
          <ul class="commonColList twoColList recipientsList">
            <li class="column">
              <div class="innerDv">
                <div class="commonColListWrap">
                  <ul class="commonColList twoColList">
                    <li class="padTN column">
                      <div class="innerDv">
                        <h2 class="headline">Recipients</h2>
                        <dl class="input_list">

                          <announcement-list-box :is-disabled="isPopupDisabled"
                                                 v-model="selectedUserIds"
                                                 :items="allUsers"
                                                 title="users"
                                                 key-prop="id"
                                                 name-prop="firstName,lastName"/>

                          <announcement-list-box :is-disabled="isPopupDisabled"
                                                 v-model="selectedUserRoles"
                                                 :items="allUserRoles"
                                                 title="userRoles"
                                                 key-prop="choice"
                                                 name-prop="niceName"/>

                          <announcement-list-box :is-disabled="isPopupDisabled"
                                                 v-model="selectedChannels"
                                                 :items="allChannels"
                                                 title="channels"
                                                 key-prop="id"
                                                 name-prop="name"/>

                          <announcement-list-box :is-disabled="isPopupDisabled"
                                                 v-model="selectedDistricts"
                                                 :items="allDistricts"
                                                 title="districts"
                                                 key-prop="id"
                                                 name-prop="name"/>

                          <announcement-list-box :is-disabled="isPopupDisabled"
                                                 v-model="selectedLanguages"
                                                 :items="allLanguages"
                                                 title="language"
                                                 key-prop="choice"
                                                 name-prop="niceName"/>

                        </dl>
                      </div>
                    </li>
                    <li class="padTN column">
                      <div class="innerDv">
                        <div class="fltRht greenTxt">{{ recipients.length }}</div>
                        <h2 class="headline">{{ x('announcementsSelected') }}</h2>
                        <ul class="recipientsSearchList" v-if="recipients.length">
                          <li v-for="recipient in recipients" :key="recipient.id">
                            <a title="Remove" @click="removeRecipient(recipient)"
                               v-if="!isPopupDisabled">
                              <i class="fa fa-close"/>
                            </a>
                            <span>{{ fullName(recipient) }}</span>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="column">
              <div class="innerDv">
                <h2 class="headline">{{ x('content') }}</h2>
                <div class="commonColListWrap">
                  <ul class="commonColList twoColList">
                    <li class="column">
                      <div class="innerDv">
                        <dl class="input_list" v-if="schemaItemByName('periodFrom')">
                          <data-editor-input-field
                            :schema="schemaItemByName('periodFrom')"
                            v-model="editedData.periodFrom"
                            :should-validate="shouldValidate"
                            @validate="inputFieldDidValidate"
                          />
                        </dl>
                      </div>
                    </li>
                    <li class="column">
                      <div class="innerDv">
                        <dl class="input_list" v-if="schemaItemByName('periodTo')">
                          <data-editor-input-field
                            :schema="schemaItemByName('periodTo')"
                            v-model="editedData.periodTo"
                            :should-validate="shouldValidate"
                            @validate="inputFieldDidValidate"
                          />
                        </dl>
                      </div>
                    </li>
                  </ul>
                </div>
                <dl class="input_list">
                  <data-editor-input-field
                    v-for="item in normalVisibleSchemaItems"
                    :schema="schemaItemByName(item.fieldName)"
                    :key="item.fieldName"
                    v-model="editedData[item.fieldName]"
                    :isDisabled="isPopupDisabled"
                    :should-validate="shouldValidate"
                    @validate="inputFieldDidValidate"
                  />
                </dl>
                <div class="uploaderFullDv">
                  <image-single ref="cloudinaryUpload"
                                v-model="picture"
                                :edit-mode="!isAddMode"
                                :image-view-mode="1"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <br>
      <div class="textright">
        <button class="lsc-button lsc-rounded-10 red" type="submit"
                @click="closeEditor">
          <icon name="fa-close"/>
          {{ x('cancel') }}
        </button>
        <button v-if="missingFieldErrorMessage.length === 0 && isPopupDisabled === false"
                class="lsc-button lsc-rounded-10 green marginLeft" @click="submitForm" type="submit">
          <icon name="fa-cloud-upload"/>
          {{ x('save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import moment from 'moment';
  import { sortedUniqBy } from 'lodash';
  import { httpGet, httpPost, httpPut } from '@/classes/httpHelper';
  import ImageSingle from '@/components/ImageUpload/ImageSingle';
  import DataEditorInputField from './DataEditorInputField';
  import EditorTopbar from './EditorTopBar';
  import AnnouncementListBox from './AnnouncementListBox';
  import translate from '../../Mixins/Translate';

  export default {
    name: 'announcement-editor',
    components: {
      EditorTopbar,
      ImageSingle,
      'announcement-list-box': AnnouncementListBox,
      'data-editor-input-field': DataEditorInputField,
    },
    mixins: [translate],
    emits: ['editorIsReady', 'columnClassComputed'],
    data() {
      return {
        allUsers: [],
        selectedUserIds: [],
        selectedUserRoles: [],
        selectedChannels: [],
        selectedDistricts: [],
        selectedLanguages: [],

        recipients: [],

        /** The contents of the editable fields */
        editedData: {},

        missingFieldErrorMessage: '',
        isPopupDisabled: false,

        finalUserList: [],
        maxImageCount: 1,
        openUploaderAtStart: false,
        isImageMandatory: false,
        picture: null,

        maxHeightStyle: {
          maxHeight: '400px',
        },

        // This property is a trigger that causes datepicker fields to retrieve data
        // (it uses jquery and is not reactive),
        // and all input fields to validate and show any error messages.
        // Remember to await this.$nextTick() to let its effects take place.
        shouldValidate: false,

        validatesOk: true,
      };
    },
    computed: {
      isAddMode() {
        return !this.editorData.id;
      },

      headline() {
        let headline = '';
        if (this.isAddMode) {
          headline = this.x('createNewAnnouncement');
        } else {
          headline = this.x('editAnnouncement');
        }
        if (this.editorData.item.name) {
          headline += ` - "${this.editorData.item.name}"`;
        }
        return headline;
      },

      /** Returns a list of the field items that should have common UI treatment. */
      normalVisibleSchemaItems() {
        if (!this.editorData) return null;
        return this.editorData.dataSchema.filter((s) => s.fieldName !== 'periodFrom'
          && s.fieldName !== 'periodTo'
          && s.fieldName !== 'picName'
          && s.userVisible);
      },
      allLanguages() {
        return this.getChoicesList('Users_language_list');
      },
      allUserRoles() {
        return this.getChoicesList('Users_userRole_list');
      },
      ...mapState(['config', 'user']),
      ...mapState('DataEditorStore', ['editorData']),
      ...mapState('CachingStore', {
        allCustomers: 'customers',
        allChains: 'chains',
        allChannels: 'channels',
        allDistricts: 'districts',
      }),
      ...mapGetters('CachingStore', ['getChoicesList']),
    },
    watch: {
      selectedUserIds() {
        this.refreshRecipientList();
      },
      selectedUserRoles() {
        this.refreshRecipientList();
      },
      selectedDistricts() {
        this.refreshRecipientList();
      },
      selectedChannels() {
        this.refreshRecipientList();
      },
      selectedLanguages() {
        this.refreshRecipientList();
      },
    },
    async mounted() {
      this.$store.dispatch('CachingStore/requireChoicesList', 'Users_language_list');
      this.$store.dispatch('CachingStore/requireChoicesList', 'Users_userRole_list');
      this.$store.dispatch('CachingStore/requireDataType', { dataType: 'districts' });
      await this.loadAllUsers();
      await this.initialize();
    },
    methods: {
      schemaItemByName(fieldName) {
        if (!this.editorData) return null;
        const items = this.editorData.dataSchema.filter((item) => item.fieldName === fieldName);
        if (items.length > 0) return items[0];
        return null;
      },

      async loadAllUsers() {
        this.allUsers = await httpGet('users');
      },

      fullName(user) {
        return `${user.firstName} ${user.lastName}`;
      },

      async loadCurrentRecipients() {
        if (this.isAddMode) return;

        this.selectedUserIds = await httpGet(`announcements/${this.editorData.id}/recipients`);
      },

      /** Consolidates all the selected checkboxes and generates a list of recipients */
      refreshRecipientList() {
        const districtsInSelectedChannels = this.allDistricts
          .filter((district) => this.selectedChannels.indexOf(district.channelId) >= 0);

        const that = this;
        const unsortedRecipients = this.allUsers.filter((user) => {
          if (that.selectedUserIds.indexOf(user.id) >= 0) return true;
          if (that.selectedUserRoles.indexOf(user.userRole) >= 0) return true;
          // If any role is selected, include users with "both" as user role.
          if (that.selectedUserRoles.length > 0 && user.userRole === 'both') return true;

          // Channels: Select users whose district is in the channel.
          if (districtsInSelectedChannels.indexOf(user.districtId) >= 0) return true;
          if (that.selectedDistricts.indexOf(user.districtId) >= 0) return true;
          // noinspection RedundantIfStatementJS
          if (that.selectedLanguages.indexOf(user.language) >= 0) return true;
          return false;
        });

        this.recipients = sortedUniqBy(unsortedRecipients, (x) => `${x.firstName} ${x.lastName}`.toLowerCase());
      },

      /** Removes the recipient and also checks if any
       * checkmarks should be removed from the listboxes. */
      removeRecipient(user) {
        this.recipients = this.recipients.filter((recipient) => recipient.id !== user.id);
        this.selectedUserIds = this.selectedUserIds.filter((id) => id !== user.id);

        // If no users with the same user role as the removed user are selected,
        // remove the user role selection, too.
        if (user.userRole === 'both' && this.recipients.length === 0) {
          // We have removed the last user, so we should remove all user role checkmarks
          this.selectedUserRoles = [];
        } else {
          const userWithSameRole = this.recipients.filter((r) => r.userRole === user.userRole);
          if (userWithSameRole.length === 0) {
            this.selectedUserRoles = this.selectedUserRoles.filter((s) => s !== user.userRole);
          }
        }

        if (user.districtId != null) {
          // DISTRICTS
          // If no users with the same district as the removed user are selected,
          // remove the district selection, too.
          const usersWithSameDistrict = this.recipients
            .filter((r) => r.districtId === user.districtId);
          if (usersWithSameDistrict.length === 0) {
            this.selectedDistricts = this.selectedDistricts.filter((s) => s !== user.districtId);
          }

          // CHANNELS
          const userDistrict = this.allDistricts.filter((d) => d.id === user.districtId)[0];
          if (userDistrict != null) {
            const districtsWithSameChannel = this.allDistricts
              .filter((d) => d.channelId != null && d.channelId === userDistrict.channelId);
            // If no users exist with any of these districts, we can remove the channel checkmark

            const usersWithSameChannel = this.recipients
              .filter((r) => districtsWithSameChannel.filter((d) => d.id === r.districtId).length >= 0);
            if (usersWithSameChannel.length === 0) {
              this.selectedChannels = this.selectedChannels
                .filter((c) => c !== userDistrict.channelId);
            }
          }
        }

        // LANGUAGES
        const usersWithSameLanguage = this.recipients.filter((r) => r.language === user.language);
        if (usersWithSameLanguage.length === 0) {
          this.selectedLanguages = this.selectedLanguages.filter((l) => l !== user.language);
        }
      },

      /** Checks that there is data for all fields, returns an array of missing fields. */
      validateItemAgainstSchema(item, schema) {
        const missingFields = [];
        for (const schemaItem of schema) {
          if (!(schemaItem.fieldName in item)) {
            missingFields.push(schemaItem.translationsName !== null
              ? this.x(schemaItem.translationsName) : schemaItem.fieldName);
          }
        }
        return missingFields;
      },

      hasAnnouncementPeriodStarted(item) {
        if (item.periodFrom) {
          const fromDate = moment(item.periodFrom.substr(0, 10), 'YYYY-MM-DD');
          return fromDate.isSameOrBefore(moment.utc());
        }
        return false;
      },

      async initialize() {
        const data = this.editorData;
        const missingFields = this.validateItemAgainstSchema(data.item, data.dataSchema);

        if (missingFields.length > 0) {
          this.missingFieldErrorMessage = missingFields.join(', ');
          this.missingFieldErrorMessage = `${this.x('editViewMissingField')} (${missingFields.join(', ')})`;
        }
        const visibleControlCount = data.dataSchema.filter((f) => f.userVisible).length;

        data.dataSchema.sort((a, b) => a.fieldSort - b.fieldSort);

        this.editedData = { ...this.editorData.item };
        if (this.editedData.picName) {
          this.picture = { name: this.editedData.picName };
        }

        // Disable the entire popup if this is edit mode and
        // start date is in the past (then it is active)
        if (!this.isAddMode) {
          this.isPopupDisabled = this.hasAnnouncementPeriodStarted(data.item);
        }

        await this.loadCurrentRecipients();
        await this.$nextTick();

        await this.$store.dispatch('closeMenu');
        this.$emit('columnClassComputed', 'max-Width950');

        await this.$nextTick();

        const popupSetting = this.calculateAvailableHeightAndClassForDynPopup2(visibleControlCount);
        this.maxHeightStyle = {
          maxHeight: `${popupSetting.availableHeight}px`,
        };

        this.$store.commit('DataEditorStore/setIsLoading', false);
        this.$emit('editorIsReady');

        // TODO: Handle resize event
      },

      closeEditor() {
        this.$store.commit('DataEditorStore/closeEditor');
      },

      calculateAvailableHeightAndClassForDynPopup2(visibleControlCount) {
        const whitePopupHeight = 30; // marTop=15 & marBot=15
        const subGridHeight = 50;
        const whitePopupContentHeight = 50; // padTop=20 & padBot=20 & borTop=5 & borBot=5
        const buttonDivHeight = 37;
        const eachElementHeightRequirement = 100;
        let availableHeight = $(window).height()
          - whitePopupHeight
          - subGridHeight
          - whitePopupContentHeight
          - buttonDivHeight
          - 20; // buffer height 20 to tackle common error

        if (availableHeight < 250) {
          availableHeight = 250;
        }

        let noOfFieldsInOneColumn = Math.round(availableHeight / eachElementHeightRequirement);
        if (noOfFieldsInOneColumn < 3) {
          noOfFieldsInOneColumn = 3;
        }

        let columnListCountClass = 'threeColList';
        let maxWidthClass = 'max-Width950';

        if (noOfFieldsInOneColumn >= visibleControlCount) {
          columnListCountClass = 'oneColList';
          maxWidthClass = 'max-Width350';
        } else if (noOfFieldsInOneColumn * 2 >= visibleControlCount) {
          columnListCountClass = 'twoColList';
          maxWidthClass = 'max-Width480';
        }

        return {
          availableHeight,
          maxWidthClass,
          columnListCountClass,
        };
      },

      inputFieldDidValidate(message) {
        console.log('Input field validation: ', message);
        if (message !== '') {
          if (this.validatesOk) this.validatesOk = false;
        }
      },

      async submitForm() {
        // Triggers validation and datepicker value retrieval
        this.shouldValidate = true;
        await this.$nextTick();

        if (!this.validatesOk) return;

        if (this.picture !== null) {
          this.editedData.picName = this.picture.name;
        }

        this.editedData.recipientIds = this.recipients.map((recipient) => recipient.id);

        const result = await this.dataEditorSave();

        if (this.isAddMode) {
          // Should reference DataTable component
          this.editorData.rowComponent.updateAfterCreate(result.data);
        } else {
          // Should reference DataRow component
          this.editorData.rowComponent.updateAfterEdit(result.data);
        }
        this.closeEditor();
      },

      async dataEditorSave() {
        this.editedData.origin = 'admin';
        try {
          const postData = JSON.stringify(this.editedData);
          console.log('Data to save: ', postData);
          let result;
          if (this.isAddMode) {
            result = await httpPost('announcements', postData);
          } else {
            result = await httpPut(`announcements/${this.editedData.id}`, postData);
          }

          console.log('Received result of post: ', result);
          if (result.error) {
            return {
              errorMessage: result.error.message,
              data: null,
            };
          }
          return {
            errorMessage: '',
            data: result,
          };
        } catch (e) {
          console.error(e);
          return {
            errorMessage: 'An error occurred during save.',
            data: null,
          };
        }
      },
    },
  };
</script>

<style scoped>
.marginLeft {
  margin-left: 15px;
}
</style>
