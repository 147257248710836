<template>
  <div class="imageItemUploading progressBar" :style="{ width: item.progress + '%' }"></div>
</template>

<script>
  /*
    This component is a progress bar used as a part of the
    ImageList component to display upload progress.
     */
  export default {
    name: 'image-item-uploading',
    props: ['item'],
  };
</script>

<style>
  .imageItemUploading.progressBar {
    background: #2c3949;
    height: 7px;
    bottom: 0;
    position: absolute;
    left: 0;
  }
</style>
