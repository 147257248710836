<template>
  <div id="hiddenArea">
    <form>
      <input type="file" ref="fileInput" id="fileElem"
             :multiple="uploadMultiple" accept="image/*" @change="handleFiles">
    </form>
  </div>
</template>

<script>
  /*
    This is a hidden component that is placed globally and accessed from the
    ImageList.vue component. It handles the actual file picker and upload to Cloudinary.
     */
  import { mapState } from 'vuex';
  import UploadEvents from '@/enums/upload-events';

  export default {
    name: 'cloudinary-upload',
    props: {
      uploadMultiple: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      UploadEvents.add,
      UploadEvents.progress,
      UploadEvents.done,
      UploadEvents.fail,
    ],
    computed: {
      ...mapState(['config']),
    },
    methods: {
      clickButton() {
        this.$refs.fileInput.click();
      },
      uploadFile(file) {
        this.$emit(UploadEvents.add, file);
        const url = `https://api.cloudinary.com/v1_1/${this.config.cloudinaryInfo.cloud_name}/upload`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        // Progress handler
        xhr.upload.addEventListener('progress', (e) => {
          this.$emit(UploadEvents.progress, {
            loaded: e.loaded,
            total: e.total,
            file,
          });
          console.log(`File upload progress. data.loaded: ${e.loaded}, data.total: ${e.total}`);
        });
        const that = this;

        // Done handler
        // This is an embedded function because it uses closure reference to xhr and the file.
        xhr.onreadystatechange = function handleChange() {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const result = JSON.parse(xhr.responseText);
            that.$emit(UploadEvents.done, {
              result,
              file,
              textStatus: 'success',
            });
          }
        };

        if (this.config.cloudinaryInfo.folder) {
          fd.append('folder', this.config.cloudinaryInfo.folder);
        }
        fd.append('upload_preset', this.config.cloudinaryInfo.upload_preset);
        fd.append('tags', 'browser_uploads'); // Optional - add tag for image admin in Cloudinary
        fd.append('file', file);
        xhr.send(fd);
      },

      // *********** Handle selected files ******************** //
      handleFiles(event) {
        const files = event.target.files;
        for (const file of files) {
          this.uploadFile(file); // call the function to upload the file
        }
      },
    },
  };
</script>

<style scoped>
  #hiddenArea {
    position: fixed;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    overflow: hidden;
  }
</style>
