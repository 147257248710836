<template>
  <div>
    <a v-if="showDeleteButtonParsed" @click="deleteImage()" class="delete">
      <icon name="fa-close"/>
    </a>
    <!--suppress HtmlUnknownTarget -->
    <img :src="getImageUrl(true)"
         :style="{ height: height + 'px', width: width + 'px' }"
         :class="{ cover: imageStyle === 'cover', contain: imageStyle === 'contain' }"
         @click="showFullscreen()" alt="image">
  </div>
</template>

<script>
  export default {
    name: 'image-item',
    props: {
      item: { required: true },
      width: { default: 100 },
      height: { default: 70 },
      imageStyle: { default: 'cover' }, // can be 'cover' or 'contain'
      showDeleteButton: { default: true },
    },
    emits: ['delete'],
    computed: {
      showDeleteButtonParsed() {
        switch (typeof this.showDeleteButton) {
          case 'boolean': return this.showDeleteButton;
          case 'string': return this.showDeleteButton.toLowerCase() === 'true';
          default: return false;
        }
      },
    },
    methods: {
      getImageUrl(isThumbnail) {
        const options = {
          format: 'jpg',
          crop: 'limit',
          effect: 'saturation:50',
          version: this.item.version,
        };
        if (isThumbnail) {
          options.width = this.width * 3;
          options.height = this.height * 3;
        }
        const el = $.cloudinary.image(this.item.name, options);
        return el[0].src;
      },
      deleteImage() {
        this.$emit('delete', this.item);
      },
      showFullscreen() {
        this.$store.commit('setZoomImage', this.getImageUrl(false));
      },
    },
  };
</script>

<style scoped>
  .delete {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    margin: 5px 0 0 5px;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: #e74c3c;
    border-radius: 100%;
    color: #ffffff;
    font-size: 18px;
  }

  /* These are applied dynamically */
  /*noinspection CssUnusedSymbol*/
  .cover {
    object-fit: cover;
  }

  /*noinspection CssUnusedSymbol*/
  .contain {
    object-fit: contain;
  }
</style>
