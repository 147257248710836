<template>
  <div class="element materialShadow">
    <div class="photoList">
      <div class="photoListItemWrapper" v-if="image !== null">
        <div class="photoListItem">
          <image-item v-if="image && image.type==='current'" :item="image"
                      @delete="o => deleteImage(o)"
                      image-style="contain"
                      :width="imageWidth" :height="imageHeight" />
          <image-item-uploading v-if="isUploading" :item="image"/>
        </div>
      </div>
      <div v-if="image === null" class="noImages" @click="clickCameraButton()">
        {{ x('noImage') }}.
      </div>
    </div>
    <button type="button" class="lsc-button lsc-rounded-10 photobutton"
            :class="{ green: image === null, grey: image !== null }"
            @click="clickCameraButton()">
      <i class="fa fa-camera"/>
    </button>
    <teleport to="#uploadContainer">
      <CloudinaryUpload ref="uploadComponent"
                        :upload-multiple="false"
                        @add="addHandler"
                        @progress="progressHandler"
                        @done="doneHandler"
                        @fail="failHandler"
      />
    </teleport>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpPost } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import CloudinaryUpload from '@/components/ImageUpload/CloudinaryUpload';
  import ImageItemUploading from './ImageItemUploading';
  import ImageItem from './ImageItem';
  import translate from '../Mixins/Translate';

  export default {
    name: 'image-single',
    components: {
      CloudinaryUpload,
      'image-item-uploading': ImageItemUploading,
      'image-item': ImageItem,
    },
    mixins: [translate],
    props: {
      editMode: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        // This references the picture to be uploaded. It might already have a picture in edit mode.
        // This property implements 'v-model' support along with the 'input' emitted event.
        // Therefore, this property should not be filled explicitly, rather, a 'v-model'
        // attribute should be used.
      },
      imageViewMode: {
        /* imageViewMode indicates the way image should be displayed when it is uploaded or
        when image is available in edit mode
        0: Normal display
        1: enlarge Image
        2: enlarged cropped image with fixed height
        */
        type: Number,
        default: 0,
      },
      coverImage: {
        // This is the name of the image that is currently the cover image.
        type: String,
      },
    },
    emits: [ComponentEventNames.updateModelValue],
    data() {
      return {
        image: this.modelValue ? {
          name: this.modelValue.name,
          type: 'current',
        } : null,
        /**
         * A list of images that have been marked for deletion during edit.
         * They are not actually deleted until the user presses OK,
         * since we need to retain them in case the user cancels the edit.
         */
        deletedImageIds: [],
        /**
         * A list of images that have been added during edit.
         * They should be deleted if cancel is pressed.
         */
        addedImageIds: [],
        imageHeight: 250,
        imageWidth: 400,
        isUploading: false,
      };
    },
    computed: {
      ...mapState(['config', 'user']),
    },
    watch: {
      modelValue() {
        // We need to explicitly watch this, because the imageIds field is only
        // populated on initialization, because this is an editable value.
        this.image = this.modelValue ? {
          name: this.modelValue.name,
          type: 'current',
        } : null;
      },
    },
    methods: {
      addHandler(data) {
        // Detected that new file(s) have been added to the upload queue.
        const filename = data.name;
        this.image = {
          name: filename,
          type: 'new',
          progress: 0,
        };

        this.isUploading = true;
      },
      progressHandler(data) {
        const percent = Math.round((data.loaded * 100.0) / data.total);
        if (this.image) {
          this.image.progress = percent;
        }
      },
      doneHandler(data) {
        if (data.textStatus === 'success') {
          const imageId = data.result.public_id;
          const imageVersion = data.result.version || '';
          const imageObj = this.image;
          imageObj.name = imageId;
          imageObj.type = 'current';
          imageObj.version = imageVersion;
          this.addedImageIds.push(imageId);
          this.$emit(ComponentEventNames.updateModelValue, { name: this.image.name });
        } else {
          // eslint-disable-next-line no-alert
          alert(this.config.translation.uploadFailedTryAgain);
        }
        this.isUploading = false;
      },
      failHandler(e, data) {
        // eslint-disable-next-line no-alert
        alert(this.config.translation.uploadFailedTryAgain);
        console.log('Upload failed.', e, data);
        this.isUploading = false;
      },
      clickCameraButton() {
        console.log('clickCameraButton');
        if (this.isUploading) return;
        if (this.image !== null) {
          console.log('Maximum number of images reached.');
          return;
        }
        this.$refs.uploadComponent.clickButton();
      },
      async deleteImage(item) {
        const publicId = item.name;
        this.image = null;
        if (this.editMode) {
          this.deletedImageIds.push(publicId);
        } else {
          await this.sendDeleteToServer(publicId);
        }

        this.$emit(ComponentEventNames.updateModelValue, null);
      },
      async sendDeleteToServer(publicId) {
        await httpPost('oldimages', [publicId]);
        console.log('Image deleted.');
      },
      /**
       * In case of edit mode, destructive image changes have not been committed.
       * They can be committed by calling this method.
       */
      async commitImageChanges() {
        // Removed images should be removed now.
        // Added images are already uploaded, they are all right.
        this.deletedImageIds.forEach(await this.sendDeleteToServer);
      },
      async rollbackImageChanges() {
        // Removed images are not really removed, so they can be ignored when we don't save changes.
        // Added images should be removed again.
        this.addedImageIds.forEach(await this.sendDeleteToServer);
      },
    },
  };
</script>

<style scoped lang="scss">
 .photoList {
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 6px;
  }

  .photoListItem {
    height: 250px;
    width: 400px;
    background: #efefef;
    overflow: hidden;
    border-radius: 10px;
    border-bottom: 4px solid rgba(0, 0, 0, 0.3);
    position: relative;
  }

  .photoListItemWrapper {
    position: relative;
    display: inline-block;
    height: 250px;
    width: 400px;
  }

  .photoList.enlargedImage {
    height: 250px !important;
  }

  .photoList.enlargedImage .photoListItem {
    height: 250px !important;
  }

  .photoList .photoListItem.hasImage {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .photoList .photoListItem.used {
    border: 2px solid #636363;
  }

  button.photobutton {
    text-align: center;
    font-size: 20px;
    margin: auto;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 4px;
  }

  button.photobutton i {
    margin: 0;
  }

  .noImages {
    background-color: #efefef;
    border-radius: 10px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .photoNumber {
    display: inline-block;
    width: 20px;
    padding-left: 2px;
    font-weight: 700;
    color: var(--ThemeTakePicH2Color);
  }

  .coverButton {
    margin-top: 5px;
    padding: 0 12px;
    width: 80px;
    text-align: center !important;
    background-color: var(--ThemeCoverButtonBg) !important;
  }

  .coverButton.selected {
    background-color: var(--ThemeCoverButtonBgSel) !important;
  }

  .coverButton i {
    margin: 0 !important;
  }

</style>
