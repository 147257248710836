/**
 * These are the events that can be emitted by the upload component.
 * Or related to uploading. Or something.
 * @type {{}}
 */
export default {
  add: 'add',
  progress: 'progress',
  done: 'done',
  fail: 'fail',

  takePicture: 'takePicture',
};
